<template>
  <div class="storeMap" style="text-align: left;">
    <van-nav-bar
        title="门店定位"
        left-text="返回"
        left-arrow
        @click-left="onReturn"
    />
    <div class="map-container" id="container"></div>
  </div>
</template>

<script>

// https://lbs.qq.com/webApi/uriV1/uriGuide/uriWebRoute
// https://lbs.amap.com/api/javascript-api/guide/services/navigation#get_data
// https://lbs.amap.com/api/webservice/guide/api/georegeo
export default {
  name: "StoreMap",
  components: {},
  data() {
    return {
      map: null,
    };
  },
  mounted(){
    this.init()
  },
  created() {
    this.fetchData()
  },
  methods: {
    init() {
      let markLocationFunc = this.markLocation;
      window.onLoad = function () {
        // this.map = new AMap.Map('container', { // eslint-disable-line no-undef
        //   resizeEnable: true
        // });
        markLocationFunc();
      };
      let key = "227a374b879e5773bf5da590229aca98";
      let url = `https://webapi.amap.com/maps?v=1.4.15&key=${key}&callback=onLoad`;
      let jsapi = document.createElement("script");
      jsapi.type = "text/javascript";
      jsapi.src = url;
      document.head.appendChild(jsapi);
    },
    markLocation(address) {
      if(!address) {
        address = this.$route.query.address;
      }
      AMap.plugin('AMap.Geocoder', function() { // eslint-disable-line no-undef
        let geocoder = new AMap.Geocoder(); // eslint-disable-line no-undef
        geocoder.getLocation(address, function(status, result) {
          if (status === 'complete' && result.info === 'OK') {
            // 经纬度
            let lng = result.geocodes[0].location.lng;
            let lat = result.geocodes[0].location.lat;

            // 地图实例
            let map = new AMap.Map("container", { // eslint-disable-line no-undef
              resizeEnable: true, // 允许缩放
              center: [lng, lat], // 设置地图的中心点
              zoom: 15, // 设置地图的缩放级别，0 - 20
            });

            // 添加标记
            let marker = new AMap.Marker({ // eslint-disable-line no-undef
              map: map,
              // 经纬度
              position: new AMap.LngLat(lng, lat), // eslint-disable-line no-undef
            });
            console.log(marker);
          } else {
            console.log('定位失败！');
          }
        });
      });
    },
    fetchData() {
    },
    onReturn() {
      this.$router.go(-1);
    }
  }
};
</script>
<style lang="scss" scoped>
#container {
  position: absolute;
  top:50px;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
